export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    // dashboard
    {
      name: 'menus',
      displayName: 'Menus',
      meta: {
        icon: '<i class="fa fa-cutlery va-icon"></i>',
      },
      disabled: true,
      children: [
        {
          name: 'items',
          displayName: 'Items',
        },
        {
          name: 'categories',
          displayName: 'Categories',
        },
        {
          name: 'menu',
          displayName: 'Menus',
        },
        {
          name: 'modifiers',
          displayName: 'Modifier Groups',
        },
        {
          name: 'offers',
          displayName: 'Offers'
        },
        {
          name: 'allergenIcons',
          displayName: 'Allergen Icons'
        }
      ],
    },
    {
      name: 'orders',
      displayName: 'Orders',
      meta: {
        icon:'<i class="fa fa-shopping-basket va-icon"></i>' ,
      },
    },
    {
      name: 'failed_orders',
      displayName: 'Failed Orders',
      meta: {
        icon:'<i class="fa fa-minus-circle va-icon"></i>' ,
      },
    },
    {
      name: 'reviewsOverview',
      displayName: 'Reviews',
      meta: {
        icon:'<i class="fas fa-comments va-icon fas fa-comments"></i>' ,
      },
      children: [
        {
          name: 'reviewQuestions',
          displayName: 'Review Questions',
        },
        {
          name: 'reviews',
          displayName: 'Reviews',
        },
        {
          name: 'itemreviews',
          displayName: 'Items Reviews',
        },
        {
          name: 'auotreply_reviews',
          displayName: 'Manage AutoReplies',
        },
      ]
    },
    {
      name: 'stores',
      displayName: 'Stores',
      meta: {
        icon:'<i class="fa fa-bank va-icon"></i>' ,
      },
      children: [
        {
          name: 'storelist',
          displayName: 'Store List',
        },
        {
          name: 'managestores',
          displayName: 'Manage Store',
        },
        {
          name:'third_party_store',
          displayName:'Payment Stores Link'
        }
      ]
    },
    {
      name: 'giftvouchers',
      displayName: 'Gift Cards',
      meta: {
        icon:'<i class="fa fa-gift va-icon fa fa-gift"></i>' ,
      },
      children: [
        {
          name: 'giftcardstype',
          displayName: 'Gift Cards Type',
        },
        {
          name: 'giftcards',
          displayName: 'Giftcards',
        },
        {
          name:'gift_redemptions',
          displayName:'Redemptions'
        },
        {
          name: 'payments',
          displayName: 'Payments',
        },
        {
          name: 'reports',
          displayName: 'Reports',
        }
      ]
    },
    {
      name: 'analytics',
      displayName: 'Analytics',
      meta: {
        icon:'<i class="fa fa-line-chart va-icon"></i>' ,
      },
    },
    // {
    //   name: 'loyalty',
    //   displayName: 'Loyalty Program',
    //   meta: {
    //     icon:"<i class='fas fa-percent va-icon'></i>" ,
    //   },
    //   children: [
    //     {
    //       name: 'loyalty_rules',
    //       displayName: 'Rules',
    //     },
    //     {
    //       name: 'loyalty_activities',
    //       displayName: 'Loyalty Activities',
    //     },
    //     {
    //       name: 'loyalty_customers',
    //       displayName: 'Loyalty Customers'
    //     }
    //   ]
    // },
    {
      name: 'usermanagement',
      displayName: 'Manage Users',
      meta: {
        icon: '<i class="fa fa-user-plus va-icon"></i>',
      },
      children: [
        {
          name: 'staffs',
          displayName: 'Staff',
        },
        {
          name: 'users',
          displayName: 'System users',
        },
        {
          name: 'roles',
          displayName: 'User roles',
        }
      ],
      
    },
    {
      name:'third_party_payment',
      displayName: 'Payments',
      meta: {
        icon:'<i class="fa fa-credit-card va-icon"></i>' ,
      },
    },
    {
      name:'all_customers',
      displayName: 'Customers',
      meta: {
        icon:'<i class="fas fa-users va-icon"></i>' ,
      },
    },
    {
      name: 'settings',
      displayName: 'Settings',
      meta: {
        icon:'<i class="fa fa-cogs va-icon fa fa-cog"></i>' ,
      },
      children: [
        {
          name: 'thirdpartysettings',
          displayName: 'Third Party Settings',
        },
        {
          name: 'pesapalsettings',
          displayName: 'Pesapal Settings',
        },
        {
          name: 'appsettings',
          displayName: 'App Config Settings',
        },
        {
          name: 'giftvouchersettings',
          displayName: 'Loyalty/Giftvoucher',
        },
        
        {
          name: 'layoutsettings',
          displayName: 'Layout Settings',
        },
        {
          name: 'shippingoptions',
          displayName: 'Shipping Options',
        },
        {
          name:'posconfigs',
          displayName:'Pos Configs'
        },
        {
          name: 'navmenus',
          displayName: 'Menus'
        },
        {
          name: 'faqs',
          displayName:'Faqs'
        }
      ]
    }
  ],
}
