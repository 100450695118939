export default {
    root: {
      name: '/',
      displayName: 'navigationRoutes.home',
    },
    routes: [
      {
        name: 'agentOrders',
        displayName: 'Orders',
        meta: {
          icon: '<i class="fa fa-shopping-basket va-icon fa fa-shopping-basket"></i>',
        },
        disabled: true,
        children: [
          {
            name: 'recent_orders',
            displayName: 'Recent Orders',
            meta: {
                icon: '<i class="fa fa-shopping-basket va-icon fa fa-shopping-basket"></i>',
              },
          }
        ],
      },
      {
        name: '',
        displayName: 'Create Order',
        meta: {
          icon: '<i class="fa fa-plus va-icon"></i>',
        }        
      },
    ],
  }
  