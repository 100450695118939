export default {
    data() {
        return {
            user: this.$store.state.user,
            // baseUrl: 'https://restaurants.reserveport.com/v1',
            baseUrl: 'https://restaurants.reserveport.com/v1',
            // dashBaseUrl: 'https://restaurants.reserveport.com/foodie-api/api/v1',
            dashBaseUrlSSO: 'https://restaurants.reserveport.com/foodie-api',
            dashBaseUrl: 'https://restaurants.reserveport.com/foodie-api/api/v1',
            token: this.$store.state.token,
            merchantID: this.$store.state.merchantId,
            appConfigs: [],
            layoutSettings: [],
            giftcardConfig: [],
            pesapalConfig: [],
            shippingOptionConfig: [],
            configs: [],
            merchant: [],
            channels: [
                {
                  "channel_name": "UBER"
                },
                {
                   "channel_name": "BOLT"
                },
                {
                   "channel_name": "JUMIA"
                },
                 {
                   "channel_name": "GLOVO"
                },
                {
                   "channel_name": "BUYMORE"
                },
                {
                   "channel_name": "LITTLE"
                },
                {
                   "channel_name": "PIKI"
                },
                {
                   "channel_name": "DUKA-D"
                },
                {
                    "channel_name": "FOODIE"
                },
                {
                    "channel_name": "KIOSK"
                },
                {
                    "channel_name": "MINIAPP"
                 },
                 {
                    "channel_name": "PDQ-POS"
                 },
                 {
                    "channel_name": "ANDO"
                 }
              ],
            UserRoles:{
                superAdmin: 1,
                admin: 2,
                storeManager: 3,
                user: 4,
                callCenter:5,
                waiters:7,
                OperationsAdmin:8,
                VoucherAdmin:9,
                FinanceAdmin:6,
            },
            PusherConfig:{
                Appid: "1701436",
                key: "35680789997c174fb98f",
                secret: "e8e7dc00cbad9ee0ebb1",
                cluster: "ap2",
                secure: true
            },
            adminRoles:[1,2,8,9,6]
        }
    },
    created: async function () {
        if(this.token !==""){
           await this.getSettings();
        }
        await this.getMerchant();
    },
    methods: {
        apiRequestConfig(headers = {}, data = undefined){
            return {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Accept': 'application/json',
                    ...headers,
                },
                data,
            }
        },
        async getSettings(){
            try{
                const url = `${this.dashBaseUrl}/configs/${this.merchantID}`;
                const response = await this.axios.get(url, this.apiRequestConfig())
                if (response.status === 200 ) {
                    this.configs = response.data.data;
                    this.appConfigs = response.data.data.app_settings;
                    this.giftcardConfig = response.data.data.giftvoucher_settings;
                    this.pesapalConfig = response.data.data.pesapal_settings;
                    this.shippingOptionConfig = response.data.data.shipping_options
                    this.layoutSettings = response.data.data.layout_settings;
                }
            }catch(err){
                //
            }

            //
        },
        async getMerchant(){
            try{
                const url = `${this.dashBaseUrl}/merchant/${this.merchantID}`;
                const response = await this.axios.get(url, this.apiRequestConfig())
                if (response.status === 200 ) {
                    this.merchant = response.data.data;
                }
            }catch(err){
                //
            }

        }
    }
}
