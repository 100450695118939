<template>
  <div class="app-layout__navbar res-navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <div v-if="isSidebarMinimized">
            <va-icon-menu-collapsed 
              @click="isSidebarMinimized = !isSidebarMinimized"
              :class="{ 'x-flip': isSidebarMinimized }"
              class="va-navbar__item"
              :color="colors.primary"
            />
          </div>
          
          <!-- <router-link :to="{ name: 'dashboard' }"> -->
          <span @click="redirectToPage()" style="cursor: pointer;">
            <img class="logo" :src="merchant.logo" :alt="merchant.name">
          </span>
          <!-- </router-link>           -->
        </div>
      </template>
      <template v-slot:center>
        <span></span>
      </template>
      <template #right>
        <app-navbar-actions
          class="app-navbar__actions md5 lg4"
          :user-name="userName"
        />
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { useColors } from 'vuestic-ui'
import { useStore } from 'vuex'
import { computed } from 'vue'
import VaIconMenuCollapsed from '@/components/icons/VaIconMenuCollapsed'
import AppNavbarActions from './components/AppNavbarActions'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';


export default {
  components: {AppNavbarActions, VaIconMenuCollapsed },
  setup() {
    const { getColors } = useColors()
    const colors = computed(() => getColors() )
    const store = useStore()

    const isSidebarMinimized = computed({
      get: () => store.state.isSidebarMinimized,
      set: (value) => store.commit('updateSidebarCollapsedState', value)
    })

    const userName = computed(() => store.state.userName)
    return {
      colors,
      isSidebarMinimized,
      userName
    }
  },
  methods:{
    redirectToPage(){    
      if(this.user.role.id== this.UserRoles.admin || this.user.role.id == this.UserRoles.superAdmin){
                    this.$router.push({
                      name: 'dashboard'
                  })
                  }else if(this.user.role.id == this.UserRoles.callCenter){
                    this.$router.push({
                        name: 'all_orders'
                    })
                    
                  }else{
                    this.$router.push({
                      name: 'orders'
                  })
                  }
    }
  },
  mixins: [ReusableDataMixins]
}
</script>

<style lang="scss" scoped>
  .va-navbar {
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>