<template>
  <va-sidebar :width="width" :minimized="minimized" :minimizedWidth="minimizedWidth">
    <!-- <div class="p-form mb-5">
      <span style="color:#C04B2D;cursor: pointer;" @click="raiseTicket"> <i class='fas fa-phone' ></i> Raise Ticket</span>
    </div> -->

    <div class="p-form" v-if="user.role.id == 1 || user.role.id == 2 || user.role.id == 3 || user.role.id == 5 || user.role.id ==6">
      <div v-if="merchants.length > 0">
        <label>Select Merchant Account</label>
        <Multiselect v-model="selectMerchant"  :close-on-select="true" :options="options" :custom-label="name" :searchable="true" :placeholder="'Select  '+type" label="name" track-by="name"></Multiselect>
      </div>
      <div v-else>
        <label>Select Merchant Account</label>
        <Multiselect v-model="selectMerchant" :disabled="true" @click="changeMerchant()" :close-on-select="true" :options="options" :custom-label="name" :searchable="true"  label="name" track-by="name"></Multiselect>
      </div>
    </div>
    <menu-minimized v-if="minimized" :items="items" />
    <menu-accordion v-else :items="items" :merchantID="merchantID"/>
    <footer>
      <div class="footer-logo">
        <img src="/img/reserveportlogo_grey.png" alt="Reserveport logo">
      </div>
      <span class="copyright">
        Copyright © 2015- {{new Date().getFullYear()}}. <br> Reserveport. All rights reserved
      </span>
    </footer>

  </va-sidebar>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import { useGlobalConfig } from 'vuestic-ui';
import MenuAccordion from './menu/MenuAccordion.vue';
import MenuMinimized from './menu/MenuMinimized.vue';
import NavigationRoutes from './NavigationRoutes';
import storeManagerRoutes from './storeManagerRoutes';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import CallCenterRoutes from './CallCenterRoutes';
import WaiterRoutes from './WaiterRoutes';

export default {
    name: "app-sidebar",
    components: {
        MenuAccordion,
        MenuMinimized,
        Multiselect
    },
    props: {
        width: { type: String, default: '16rem' },
        color: { type: String, default: "secondary" },
        minimized: { type: Boolean, required: true },
        minimizedWidth: {
            type: Boolean,
            required: false,
            default: undefined
        },
    },
    data() {
        return {
            items: [],
            selectMerchant: this.merchantID,
            options: [],
            type: 'merchants',
            merchants: [],
            superAdminMenu:[],
            adminMenu:[],
            storeManagerMenu:[],
            callCenterMenu:[],
            financeAdminMenu:[],
            sideMenu:[],
            Roles: {
                superAdmin: 1,
                admin:2,
                storeManager: 3,
                user:4,
                callCenter: 5,
                financeAdmin:6,
                waiter:7
            }
        };
    },
    mounted: async function ()  {
        switch(this.user.role.id) {
            case this.Roles.superAdmin:
                this.items = NavigationRoutes.routes;
                break;
            case this.Roles.storeManager:
                this.items = storeManagerRoutes.routes;
                break;
            case this.Roles.callCenter:
                this.items = CallCenterRoutes.routes;
                break;
            case this.Roles.waiter:
                this.items = WaiterRoutes.routes;
                break;
            default:
                this.items = NavigationRoutes.routes;
        } 

        this.getNavigationMenus();
        this.getMerchants();
        this.selectMerchant = this.merchantID;
        
    },
    watch:{
        selectMerchant() {
            this.changeMerchant()
        }
    },
    computed: {
        computedClass() {
            return {
                "app-sidebar--minimized": this.minimized
            };
        },
        colors() {
            return useGlobalConfig().getGlobalConfig().colors
        },
    },
    methods: {
        async getRedirectRoute(merchantId){
            try {
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                const url = `${this.dashBaseUrl}/redirect-route/${merchantId}`
                const resp = await this.axios.get(url,config)
                if(resp.status == 200){
                    const responseData = resp.data.data 
                    if(responseData.length !=0 && responseData[this.user.role.id].redirect_route !=undefined){
                        this.$router.push({
                            name: responseData[this.user.role.id].redirect_route
                        })
                        
                    }   
                }
            } catch (error) {
                // console.log("error redirect",error)
            }
            
        },
        async getNavigationMenus(){
            try{
                const url = `${this.dashBaseUrl}/formated-menu/${this.merchantID}`
                const res = await this.axios.get(url,this.apiRequestConfig())
                if(res.status==200){
                    const menu = res.data.data
                    for(let m=0; m < menu.length; m++){
                        if(menu[m][this.user.role.slug] !=undefined){
                            this.sideMenu = menu[m][this.user.role.slug]
                            break;
                        }
                    }
                    if(this.sideMenu.length > 0){
                        this.items = this.sideMenu
                    }else{
                        switch(this.user.role.id) {
                            case this.Roles.storeManager:
                                this.items = storeManagerRoutes.routes;
                                break;
                            case this.Roles.callCenter:
                                this.items = CallCenterRoutes.routes;
                                break;
                            case this.Roles.waiter:
                                this.items = WaiterRoutes.routes;
                                break;
                            default:
                                this.items = NavigationRoutes.routes;
                        } 
                    }
                }
            }catch(error){
                switch(this.user.role.id) {
                    case this.Roles.storeManager:
                        this.items = storeManagerRoutes.routes;
                        break;
                    case this.Roles.callCenter:
                        this.items = CallCenterRoutes.routes;
                        break;
                    case this.Roles.waiter:
                        this.items = WaiterRoutes.routes;
                        break;
                    default:
                        this.items = NavigationRoutes.routes;
                }
            }
            
        },
        async getMerchants() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/merchants`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    if (this.user.role.id == 1) {
                        this.merchants = response.data.data;
                        for (let x = 0; x < this.merchants.length; x++) {
                            this.options.push({
                                'value': this.merchants[x].id,
                                'name': this.merchants[x].name,
                                'disabled': this.merchants[x].status == 1 ? false :true
                            })
                        }

                    } else {
                        this.type = 'Brand'
                        this.getMerchantById()
                    }

                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
        async getMerchantById() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/related-merchants/${this.merchantID}?user_id=${this.user.id}`;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.merchants = response.data.data;
                    for (let x = 0; x < this.merchants.length; x++) {
                        this.options.push({
                            'value': this.merchants[x].id,
                            'name': this.merchants[x].name
                        })
                    }
                }
            }).catch((error) => {
                console.log(error)
            });
        },

        changeMerchant() {
            const serchparams = {
                'type_filter': true,
                'item_search': '',
                'category_search': ''
            };
            this.$store.commit('updateSearch', serchparams);
            this.$store.commit('setMerchantId', this.selectMerchant)
            this.getRedirectRoute(this.selectMerchant);
            if (this.selectMerchant !== 0 && this.merchantID !== this.selectMerchant) {
                window.location.reload()
                // setTimeout(()=>{window.location.reload();},10)           
            }

        }
    },
    mixins: [ReusableDataMixins]
};
</script>

<style lang="scss">
.va-sidebar {
    .va-collapse__body {
        margin-top: 0 !important;
    }

    &__menu {
        padding: 2rem 0;

        &__inner {
            padding-bottom: 8rem;
        }
    }

    &-item {
        &-content {
            padding: 0.75rem 1rem;
        }

        &__icon {
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>

<style lang="scss" scoped>
.va-sidebar {
    flex-shrink: 0;
    background-color: #F0F0F0 !important;
    color: #333333 !important;

}

.merchant_search_bar {
    margin-left: 20px;
    margin-bottom: 30px;
}

.copyright {
    bottom: 0px;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-align: center;

    /* Reserveport Grey */
    // color: #8A8A8A;
}

// .va-sidebar--minimized {
//   width: auto !important;
// }
</style>
