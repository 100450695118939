import { VuesticPlugin} from 'vuestic-ui';
import { createApp } from 'vue';
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Datepicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css';


// import CKEditor from '@ckeditor/ckeditor5-vue';



import vuesticGlobalConfig from './services/vuestic-ui/global-config';


const options: PluginOptions = {
  // position: "top-right",
  timeout: 10000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

const app = createApp(App);
app.use(store);
app.use(router);

app.component('Datepicker', Datepicker);

app.use(Toast, options);
// app.use(CKEditor);
app.use(VueAxios, axios);
app.use(VuesticPlugin, vuesticGlobalConfig);
app.mount('#app');

