<template>
  <router-view/>
</template>

<style lang="scss">
@import '~@/sass/main.scss';
@import '../public/assets/custom.css';
#app {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

body {
  margin: 0;
  background: #F0F0F0;
}
</style>
