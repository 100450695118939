export default {
    root: {
      name: '/',
      displayName: 'navigationRoutes.home',
    },
    routes: [
      {
        name: 'menus',
        displayName: 'Menus',
        meta: {
          icon: '<i class="fa fa-cutlery va-icon va-icon"></i>',
        },
        disabled: true,
        children: [
          {
            name: 'items',
            displayName: 'Items',
          },
        ],
      },
      {
        name: 'orders',
        displayName: 'Orders',
        meta: {
          icon:'<i class="fa fa-shopping-basket va-icon"></i>' ,
        },
      },
    ],
  }
  