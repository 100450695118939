import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import moment from 'moment';
// import * as cookie from 'js-cookie'
// import * as crypto from "crypto-js"

// const cookieName = 'cookieName'

// const storageKey = 'key'
// const encryptionToken = "gwerdserqwqwexxfer"

const vuexLocalStorage = new VuexPersistence({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage:  window.sessionStorage //      window.localStorage, // or
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export default createStore({
  strict: false, // process.env.NODE_ENV !== 'production',
  state: {
    isSidebarMinimized: false,
    merchantId:0,
    userName: 'Foodie',
    token: '',
    modifierItems: [],
    tokenTimeCreated: '',
    expiryTime: '',
    itemStatus:'',
    itemTypeFilter:'',
    itemCategorySearch:'',
    modifiers: [],
    user: [],
    gcardstartDate: moment().startOf('month').format('YYYY-MM-DD'),
    gcardendDate: moment().format('YYYY-MM-DD'),
    gcardlocation:'',
    ordReportstartDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
    ordReportendDate: moment().format('YYYY-MM-DD'),
    rStartDate:moment().subtract(7,'d').format('YYYY-MM-DD'),
    rEndDate:moment().format('YYYY-MM-DD'),
    ordReportlocation:'',
    gvlimit: '',
    products: [],
    cartproducts: [],
    freeProducts: [],
    productFreeItem:{},
    isFutureOrder: 0,
    itemsRecordPerPage: 10,
    ordersRecordPerPage: 50,
    categoryRecordsPerPage:10,
    modifiersRecordsPerPage: 10,
    storesRecordsPerPage:10,
    giftCardRecordsPerPage: 10,
    offersRecordPerPage:10,
    customerRecordPerPage:10,
    voucherPaymentRecordPerPage:10
  },
  // actions:{},
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    changeUserName(state, newUserName) {
      state.userName = newUserName
    },
    setFutureOrder(state,futureOrder){
      state.isFutureOrder = futureOrder
    },
    addModifierItems(state, items) {
      state.modifierItems = items
      // looping through items to check if it item to be added already exists
    },
    setPageLimit(state,payload){
      switch(payload.type){
        case "item":
          state.itemsRecordPerPage = payload.size
          break;
        case "category":
          state.categoryRecordsPerPage = payload.size
          break;
        case "modifier":
          state.modifiersRecordsPerPage  = payload.size
          break;
        case "giftcards":
          state.giftCardRecordsPerPage  = payload.size
          break;
        case "stores":
          state.storesRecordsPerPage = payload.size
          break;
        case "orders":
          state.ordersRecordPerPage = payload.size
          break;
        case "offers":
          state.offersRecordPerPage = payload.size
          break;
        case "vouchers_payment":
          state.voucherPaymentRecordPerPage = payload.size
          break;
        case "customers":
          state.customerRecordPerPage = payload.size
          break
      }
    },
    setMerchantId(state,merchantID){
      state.merchantId = merchantID.toString();
    },
    addModifierGroups(state,modifiers) {
      state.modifiers = modifiers
    },
    updateProducts(state,payload){
      state.products = payload.products;
      state.freeProducts = payload.freeProducts;
      state.modifiers = payload.modifiers
      state.productFreeItem = payload.productFreeItem
    },
    updateSearch(state,data){
      state.itemStatus = data.item_search;
      state.itemCategorySearch = data.category_search;
      state.itemTypeFilter = data.type_filter
    },
    loginUser(state,data){
      state.userName = data.user.name;
      state.tokenTimeCreated = data.date_created;
      state.expiryTime = data.expires_in;
      state.token = data.access_token;
      state.user = data.user;
    },
    logOutUser(state){
      state.userName = 'Foodie';
      state.tokenTimeCreated = '';
      state.expiryTime = '';
      state.token = '';
      state.merchantId = 0;
      state.user = [];
    },
    updateGvReportSearchData(state,payload){
      state.gcardstartDate = payload.start_date;
      state.gcardendDate = payload.end_date;
      state.gcardlocation = payload.location;
      state.gvlimit = payload.limit;
    },
    updateOReportSearchData(state,payload){
      state.ordReportstartDate = payload.start_date;
      state.ordReportendDate = payload.end_date;
      state.ordReportlocation = payload.location;
    }
  },
  plugins: [vuexLocalStorage.plugin],
})
