<template>
  <va-accordion class="sidebar-accordion va-sidebar__menu__inner" v-model="accordionValue" multiply>
    <va-collapse v-for="(route, idx) in items" :key="idx">

      <template #header>
        <div class="menu_item_header">
          <div v-if="route.displayName !='Create Order'">
            <va-sidebar-item :active="isRouteActive(route)" :to="route.children ? undefined : { name: route.name }">
              <va-sidebar-item-content>
                <va-icon :name="route.meta.icon" class="va-sidebar-item__icon" />

                <va-sidebar-item-title>
                  {{ route.displayName }}
                </va-sidebar-item-title>

                <va-icon v-if="route.children" :name="accordionValue[idx] ? 'expand_less' : 'expand_more'" />
              </va-sidebar-item-content>
            </va-sidebar-item>
          </div>
          <div v-if="route.displayName =='Create Order'">
            <span class="va-sidebar__item va-sidebar-item" @click="redirecTorder()"  style="color: inherit !important;">
              <div class="va-sidebar__item__content va-sidebar-item-content" data-v-a0978908="">
                <i class="fa fa-plus va-icon" style="font-size: 19px;"></i>
                <div class="va-sidebar__title va-sidebar-item-title" data-v-a0978908="">
                  {{ route.displayName }}
                </div>
              </div>
            </span>
          </div>
        </div>
      </template>

      <template v-for="(child, index) in route.children" :key="index">
        <div class="menu_child_item">
          <va-sidebar-item :active="isRouteActive(child)" :to="{ name: child.name }">
            <va-sidebar-item-content>
              <div class="va-sidebar-item__icon" />
              <va-sidebar-item-title>
                {{child.displayName}}
              </va-sidebar-item-title>
            </va-sidebar-item-content>
          </va-sidebar-item>
        </div>

      </template>
    </va-collapse>
  </va-accordion>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
export default {
    name: "AppMenuAccordion",
    props: {
        items: { type: Array, default: () => [] },
        merchantID: null
        // appSettings: []
        // client_app_url
    },
    data() {
        return {
            accordionValue: [],
            appSettings: []
        }
    },
    mounted() {
        this.accordionValue = this.items.map(i => this.isItemExpanded(i));
        this.getAppSettings()
    },
    methods: {
        redirecTorder() {
          if(this.appSettings.client_app_url != null){
            const url = `${this.appSettings.client_app_url}/#/products?user_id=${this.user.id}`
            window.open(url, '_blank');
          }
          console.log('client url',this.appSettings.client_app_url)
        },
        async getAppSettings(){
          const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = `${this.dashBaseUrl}/configs/${this.merchantID}`;
          const response = await this.axios.get(url, config)
          if (response.status === 200) {
            this.appSettings = response.data.data.app_settings;
          }
        },
        isGroup(item) {
            return !!item.children;
        },
        isRouteActive(item) {
            return item.name === this.$route.name;
        },
        isItemExpanded(item) {
            if (!item.children) {
                return false;
            }

            const isCurrentItemActive = this.isRouteActive(item);
            const isChildActive = !!item.children.find(child =>
                child.children ? this.isItemExpanded(child) : this.isRouteActive(child)
            );

            return isCurrentItemActive || isChildActive;
        }
    },
    mixins: [ReusableDataMixins]
};
</script>

<style lang="scss" scoped>
.menu_item_header {
    background-color: #d9d9d9 !important;
}
</style>
