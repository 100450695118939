<template>
  <va-dropdown
    class="profile-dropdown"
    v-model="isShown"
    boundary-body
    position="bottom"
    :offset="[0, 13]"
  >
    <template #anchor>
      <span class="profile-dropdown__anchor">
        <slot/>
        <va-icon
          class="px-2"
          :name="isShown ? 'angle_up' :'angle_down'"
          :color="theme.primary"
        />
      </span>
    </template>
    <va-dropdown-content class="profile-dropdown__content">
      <va-list-item
        v-for="option in options"
        :key="option.name"
      >
        <span class="profile-dropdown__item" style="cursor:pointer" @click="logOut()"> {{ option.name }}</span>
      </va-list-item>
    </va-dropdown-content>
  </va-dropdown>
</template>

<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import { useGlobalConfig } from 'vuestic-ui';
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: 'profile-section',
  data () {
    return {
      isShown: false,
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          name: 'logout',
          redirectTo: 'login',
        },
      ],
    },
  },
  computed: {
    theme() { return useGlobalConfig().getGlobalConfig() },
  },
  methods: {
    logOut(){
       const url = this.dashBaseUrl + "/auth/logout";
        const config = {
              headers: { 'Authorization': `Bearer ${this.token}` }
        };
       this.axios.post(url,{},config).then((response) => {
          if(response.status === 200){
            localStorage.clear(); 
            this.$store.replaceState({})
            if (response.data.url !=undefined) {
              window.location.href = response.data.url
            }else{
              toast.success(response.data.message)
              this.$store.commit('logOutUser');
              this.$router.push({
                  name: 'login'
              })

            }
            
          }
        }).catch(() => {
          this.$store.commit('logOutUser');
            this.$router.push({
                name: 'login'
            })
        });
    }
  },
  mixins: [ReusableDataMixins]
}
</script>

<style lang="scss">

.profile-dropdown {
  cursor: pointer;

  .va-dropdown-popper__anchor {
    display: flex;
    justify-content: flex-end;
  }

  &__content {
    width: 8rem;
  }

  &__item {
    display: block;
    color: var(--va-gray);

    &:hover,
    &:active {
      color: var(--va-primary);
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
