import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import AuthLayout from '@/layout/auth-layout.vue';
import AppLayout from '@/layout/app-layout.vue';
import store from '@/store';
// import moment from 'moment';


import RouteViewComponent from './route-view.vue'
// import UIRoute from '@/pages/admin/ui/route'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)*",
    redirect: { name: 'orders' },
  },
  {
    name: 'merchants',
    path: '/merchants',
    component: () => import('@/pages/merchant/merchants.vue'),
  },
  {
    name: 'groups',
    path: '/groups',
    component: () => import('@/pages/merchant/groups/groups.vue'),
  },
  {
    name: 'createmerchant',
    path: '/create-merchants',
    component: () => import('@/pages/merchant/newMerchant.vue'),
  },
  {
    name: 'support_tickets',
    path: '/support-tickets',
    component: () => import('@/pages/support/ticket.vue'),
  },
  {
    name: 'updatemerchant',
    path: '/update-merchant/:id',
    component: () => import('@/pages/merchant/updateMerchant.vue'),
  },

  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    children: [
      {
        name: 'menus',
        path: 'menus',
        component: RouteViewComponent,
        children: [
          {
            // alergyIcons
            name: 'allergenIcons',
            path: 'allergen-icons',
            component: () => import('@/pages/admin/menus/allergenicon.vue')
          },
          {
            name: 'items',
            path: 'items',
            component: () => import('@/pages/admin/menus/item/Items.vue'),
          },
          {
            name: 'createitem',
            path: 'create-item',
            component: () => import('@/pages/admin/menus/item/CreateItem.vue'),
          },
          {
            name: 'updateitem',
            path: 'update-item/:itemid',
            component: () => import('@/pages/admin/menus/item/UpdateItem.vue'),
          },
          {
            name: 'activity_log',
            path:'activity-logs/:id',
            component: () => import('@/pages/admin/menus/activityLogs.vue')
          },
          {
            name: 'categories',
            path: 'categories',
            component: () => import('@/pages/admin/menus/category/Category.vue'),
          },
          {
            name: 'createcategory',
            path: 'create-category',
            component: () => import('@/pages/admin/menus/category/CreateCategory.vue'),
          },
          {
            name: 'updatecategory',
            path: 'update-category/:categoryid',
            component: () => import('@/pages/admin/menus/category/UpdateCategory.vue'),
          },
          {
            name: 'menu',
            path: 'menu',
            component: () => import('@/pages/admin/menus/menu/Menu.vue'),
          },
          {
            name: 'createmenu',
            path: 'create-menu',
            component: () => import('@/pages/admin/menus/menu/CreateMenu.vue'),
          },
          {
            name: 'updatemenu',
            path: 'update-menu/:menuid',
            component: () => import('@/pages/admin/menus/menu/UpdateMenu.vue'),
          },
          {
            name: 'modifiers',
            path: 'modifiers',
            component: () => import('@/pages/admin/menus/modifiers/Modifiers.vue'),
          },
          {
            name: 'craetemodifier',
            path: 'create-modifier',
            component: () => import('@/pages/admin/menus/modifiers/CreateModifiers.vue'),
          },
          {
            name: 'updatemodifier',
            path: 'update-modifier/:modifierid',
            component: () => import('@/pages/admin/menus/modifiers/UpdateModifiers.vue'),
          },
          {
            name: 'offers',
            path: 'offers',
            component: () => import('@/pages/admin/menus/offers/Offers.vue'),
          },
          {
            name: 'new_offer',
            path: 'create-offer',
            component: () => import('@/pages/admin/menus/offers/createOffer.vue')
          },
          {
            name: 'updateoffer',
            path: 'update-offer/:offerId',
            component: () => import('@/pages/admin/menus/offers/updateOffer.vue')
          }
        ],
      },
      {
        name: 'analytics',
        path: 'analytics',
        component: () => import('@/pages/admin/dashboard/analytics.vue'),
      },
      {
        name: 'orders',
        path: 'orders',
        component: () => import('@/pages/admin/orders/Orders.vue'),
      },
      {
        name:'failed_orders',
        path:'failed-orders',
        component: () => import('@/pages/admin/orders/FailedOrders.vue')
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/pages/admin/dashboard/dashboard.vue'),
      },
      {
        name: 'dashboard_details',
        path: 'dashboard-detail/:type',
        component: () => import('@/pages/admin/dashboard/dashboardDetail.vue'),
      },
      {
        name:'merchants_report',
        path:'merchants-report',
        component:() => import('@/pages/admin/dashboard/merchantsReport.vue')
      },
      {
        name: 'offer_details',
        path:'offer_details/:type',
        component: () => import('@/pages/admin/dashboard/reportDetails/offerDetails/offerDetails.vue')
      },
      {
        name: 'stores',
        path: 'stores',
        component: RouteViewComponent,
        children: [
          {
            name: 'storelist',
            path: 'store-list',
            component: () => import('@/pages/admin/stores/StoreList.vue'),
          },
          {
            name: 'managestores',
            path: 'manage-stores',
            component: () => import('@/pages/admin/stores/ManageStore.vue'),
          },
          {
            name: 'createstore',
            path: 'create-store',
            component: () => import('@/pages/admin/stores/CreateStore.vue'),
          },
          {
            name: 'updatestore',
            path: 'update-store/:storeid',
            component: () => import('@/pages/admin/stores/UpdateStore.vue'),
          }

        ]
      },
      {
        name: 'loyalty',
        path: 'loyalties',
        component: RouteViewComponent,
        children: [
          {
            name: 'loyalty_rules',
            path: 'loyalty-rules',
            component: () => import('@/pages/admin/loyalty/Rules.vue'),
          },
          {
            name: 'loyalty_activities',
            path: 'loyalty-activities',
            component: () => import('@/pages/admin/loyalty/LoyaltyActivities.vue'),
          },
          {
            name: 'loyalty_activity_detail',
            path: 'loyalty-activity/:id',
            component: () => import('@/pages/admin/loyalty/activityDetails.vue')
          },
          {
            name: 'loyalty_rule_setup',
            path: 'loyalty-rule-setup',
            component: ()=> import('@/pages/admin/loyalty/RuleSetup.vue')
          },
          {
            name: 'loyalty_customers',
            path: 'loyalty-customers',
            component: ()=> import('@/pages/admin/loyalty/loyaltyCustomers.vue')
          },
          {
            name: 'new_loyalty_customer',
            path: 'creat_loyalty_customer',
            component: () => import('@/pages/admin/loyalty/newCustomer.vue')
          }
        ]
      },
      {
        name:'giftvouchers',
        path:'giftcards',
        component: RouteViewComponent,
        children : [
          {
            name: 'giftcardstype',
            path: 'giftcards-type',
            component: () => import('@/pages/admin/giftcards/vouchers/giftcardType.vue'),
          },
          {
            name: 'create_giftcards_type',
            path: 'create-giftcards-type',
            component: () => import('@/pages/admin/giftcards/vouchers/createGiftcardType.vue'),
          },
          {
            name: 'update_giftcards_type',
            path: 'update-giftcards-type/:id',
            component: () => import('@/pages/admin/giftcards/vouchers/updateGiftcardType.vue'),
          },
          {
            name: 'giftcards',
            path: 'giftcards',
            component: () => import('@/pages/admin/giftcards/giftvouchers/giftcards.vue'),
          },
          {
            name:'giftcardDetail',
            path:'giftcard-details/:id',
            component: () => import('@/pages/admin/giftcards/giftvouchers/giftCardDetails.vue'),
          },
          {
            name: 'bulkgenerate',
            path: 'bulk-generate',
            component: () => import('@/pages/admin/giftcards/giftvouchers/bulkGenerate.vue'),
          },
          {
            name: 'payments',
            path: 'payments',
            component: () => import('@/pages/admin/giftcards/payments.vue'),
          },
          {
            name: 'reports',
            path: 'gift-cards-reports',
            component: () => import('@/pages/admin/giftcards/reports.vue'),
          },
          {
            name: 'reportDetail',
            path: 'report-detail/:type',
            component: () => import('@/pages/admin/giftcards/reportDetails.vue'),
          },
          {
            name:'redemption',
            path:'redemption/:vouche_id',
            component: () => import('@/pages/admin/giftcards/giftvouchers/redemptionHistory.vue'),
          },
          {
            name:'gift_redemptions',
            path:'giftcards/redemptions',
            component: () => import('@/pages/admin/giftcards/redemption.vue')
          }
        ]
      },
      {
        name: 'usermanagement',
        path: 'manage-user',
        component: RouteViewComponent,
        children: [
          {
            name: 'users',
            path: 'users',
            component: () => import('@/pages/admin/usersManagement/users/users.vue'),
          },
          {
            name: 'createuser',
            path: 'create-user',
            component: () => import('@/pages/admin/usersManagement/users/createUser.vue'),
          },
          {
            name: 'updateuser',
            path: 'update-user/:id',
            component: () => import('@/pages/admin/usersManagement/users/updateUser.vue'),
          },
          {
            name: 'roles',
            path: 'roles',
            component: () => import('@/pages/admin/usersManagement/roles/roles.vue'),
          },
          {
            name: 'createrole',
            path: 'create-role',
            component: () => import('@/pages/admin/usersManagement/roles/createRole.vue'),
          },
          {
            name: 'updaterole',
            path: 'update-role/:id',
            component: () => import('@/pages/admin/usersManagement/roles/updateRole.vue'),
          },
          {
            name: 'staffs',
            path: 'staffs',
            component: () => import('@/pages/admin/usersManagement/staff/staffs.vue'),
          },
          {
            name: 'createstaff',
            path: 'create-staff',
            component: () => import('@/pages/admin/usersManagement/staff/newStaff.vue'),
          },
          {
            name: 'updatestaff',
            path: 'update-staff/:id',
            component: () => import('@/pages/admin/usersManagement/staff/updateStaff.vue'),
          },
        ],
      },
      {
        name: 'settings',
        path: 'settings',
        component: RouteViewComponent,
        children: [
          {
            name: 'thirdpartysettings',
            path: 'thirdparty-settings',
            component: () => import('@/pages/admin/settings/settings.vue'),
          },
          {
            name: 'pesapalsettings',
            path: 'pesapal-settings',
            component: () => import('@/pages/admin/settings/pesapalSettings.vue'),
          },
          {
            name: 'appsettings',
            path: 'app-settings',
            component: () => import('@/pages/admin/settings/appSettings.vue'),
          },
          {
            name: 'giftvouchersettings',
            path: 'giftvoucher-settings',
            component: () => import('@/pages/admin/settings/giftVoucherSettings.vue'),
          },
          {
            name: 'shippingoptions',
            path: 'shipping-options',
            component: () => import('@/pages/admin/settings/shippingOptions.vue'),
          },
          {
            name: 'createshippingoptions',
            path: 'create-shipping-options',
            component: () => import('@/pages/admin/settings/createShippingOptions.vue'),
          },
          {
            name: 'updateshippingoptions',
            path: 'update-shippings/:id',
            component: () => import('@/pages/admin/settings/editShippingOptions.vue'),
          },
          {
            name: 'layoutsettings',
            path: 'layout-settings',
            component: () => import('@/pages/admin/settings/layoutSettings.vue'),
          },
          {
            name: 'posconfigs',
            path: 'pos-configs',
            component: () => import('@/pages/admin/settings/posConfigs.vue')
          },
          {
            name: 'faqs',
            path:'faqs',
            component: () => import('@/pages/admin/settings/Faqs/Faqs.vue')
          }
        ]
      },
      {
        name:'agentOrders',
        path:'dashboard-orders',
        component: RouteViewComponent,
        children : [
          {
            name: 'all_orders',
            path: 'all-orders',
            component: () => import('@/pages/callcenter_dash/orders/allOrders.vue'),
          },
          {
            name: 'recent_orders',
            path: 'recent-orders',
            component: () => import('@/pages/callcenter_dash/orders/recentOrders.vue'),
          },
          {
            name: 'future_orders',
            path: 'future-orders',
            component: () => import('@/pages/callcenter_dash/orders/futureOrders.vue')
          },
          {
            name: 'order_details',
            path:'order-details/:order_id',
            component: () => import('@/pages/callcenter_dash/orders/orderDetails.vue')
          }

        ]
      },
      {
        name:'customers',
        path:'customers',
        component: () => import('@/pages/callcenter_dash/customers/customers.vue')
      },
      {
        name:'customer_details',
        path:'/customer-detail/:id',
        component: () => import('@/pages/callcenter_dash/customers/customerDetails.vue')
      },
      {
        name: 'third_party_payment',
        path:'all-payments',
        component: () => import('@/pages/admin/thirdPayments/payments.vue')
      },
      {
        name:'third_party_store',
        path: 'third-party-stores-link',
        component:() => import('@/pages/admin/thirdPayments/linkedStores.vue')
      },
      {
        name: 'call_center_vouchers',
        path:'/gift-vouchers',
        component: () => import('@/pages/callcenter_dash/giftcards/giftcards.vue')

      },
      {
        name: 'reviewsOverview',
        path: 'reviews',
        component: RouteViewComponent,
        children: [
          {
            name: 'reviewQuestions',
            path: '/review-questions',
            component: () => import('@/pages/admin/reviews/reviewQuestion.vue'),
          },
          {
            name: 'reviews',
            path: '/reviews',
            component: () => import('@/pages/admin/reviews/reviews.vue'),
          },
          {
            name: 'itemreviews',
            path: '/item-reviews',
            component: () => import('@/pages/admin/reviews/itemReviews.vue'),
          },
          {
            name: 'auotreply_reviews',
            path: '/auto-replies',
            component: () => import('@/pages/admin/reviews/autoReplyReviews.vue'),
          },
        ]
      },

      // menus
      {
        name:'navmenus',
        path:'/menus',
        component: () => import('@/pages/admin/settings/menus.vue')
      },

      {
        name: 'menu_items',
        path:'/menu-items/:id',
        component: () => import('@/pages/admin/settings/menuItems.vue')
      },
      {
        name: 'new_menu_items',
        path:'/new-menu-items/:id',
        component: () => import('@/pages/admin/settings/newMenuItem.vue')
      },
      {
        name: 'update_menu',
        path:'/update-menu-items/:id',
        component: () => import('@/pages/admin/settings/updateMenuItem.vue')
      },
      {
        name:'all_customers',
        path:'admin-customers',
        component: () => import('@/pages/admin/customers/customers.vue')
      },
      {
        name:'customer_info',
        path:'/customer-info/:id',
        component: () => import('@/pages/admin/customers/customerDetail.vue')
      }
      // UIRoute,
    ]
  },
  {
    name: 'login',
    path: '/auth/login',
    component: () => import('@/pages/auth/login/Login.vue'),
    beforeEnter: () => {
      const token = store.state.token;
      const dateCreated = new Date(store.state.tokenTimeCreated);
      let tokenDuration = store.state.expiryTime? parseInt(store.state.expiryTime): 0;
      tokenDuration = tokenDuration * 1000;
      const now = new Date();
      const diff = now.getTime() - dateCreated.getTime();
      if(token && (diff < tokenDuration)){
        return { name: 'items'}
      }
    },
  },
  {
    name: 'staff_login',
    path: '/staff/login',
    component: () => import('@/pages/auth/login/staffLogin.vue'),
    beforeEnter: () => {
      const token = store.state.token;
      const dateCreated = new Date(store.state.tokenTimeCreated);
      let tokenDuration = store.state.expiryTime? parseInt(store.state.expiryTime): 0;
      tokenDuration = tokenDuration * 1000;
      const now = new Date();
      const diff = now.getTime() - dateCreated.getTime();
      if(token && diff < tokenDuration){
        return { name: 'items'}
      }
    },
  },
  {
    name: 'signup',
    path: '/auth/signup',
    component: () => import('@/pages/auth/signup/Signup.vue'),
  },
  {
    name: 'recover-password',
    path: '/auth/recover-password',
    component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
  },
  {
    name: 'reset-password',
    path: '/password/reset/:token',
    component: () => import('@/pages/auth/recover-password/resetPassword.vue'),
  },
  {
    name: 'create-password',
    path: '/auth/create-new-password/:userId',
    component: () => import('@/pages/auth/recover-password/createPassword.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

router.beforeEach((to, from,next) => {
  let isAuthenticated = false;
  const token = store.state.token;
  const dateCreated = new Date(store.state.tokenTimeCreated);
  let tokenDuration = store.state.expiryTime? parseInt(store.state.expiryTime): 0;
  tokenDuration = tokenDuration * 1000;
  const now = new Date();
  const diff = now.getTime() - dateCreated.getTime();
  if(token && diff < tokenDuration){
    isAuthenticated = true
  }
  if (to.name !== 'signup' && to.name !== 'login' && to.name !=='staff_login' && !isAuthenticated) {
    if(to.name === 'recover-password' ||  to.name === 'reset-password' || to.name === 'create-password'){
      next();
    }else{
      next({ name: 'login' });
    }

  }
  else {next()}
})


export default router
